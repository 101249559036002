import React from "react";
import { Link } from "react-router-dom";
import Politicas from "../media/institucional/Política de tratamiento y protección de datos personales.pdf";
import Estados2022 from "../media/institucional/Estados Financieros Año 2022.pdf";
class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <React.Fragment>
                <button
                    className="burger-button phone-only"
                    onClick={() => this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }))}
                >
                    <i className="fa fa-bars"></i>
                </button>
                {this.state.isMenuOpen && (
                <div className="menu-overlay">
                <ul className={"nav-bar phone-only"}>
                    <Link to={"./creaciones"} onClick={() => this.setState({ isMenuOpen: false })}>
                        <li>
                            <h1>Creaciones</h1>
                        </li>
                    </Link>
                    <li className="dropdown">
                        <h1 className="dropbtn">
                            Nosotros
                            <i className="fa fa-caret-down" />
                        </h1>
                        <div className="dropdown-content">
                            <Link to={"./Grupo"} onClick={() => this.setState({ isMenuOpen: false })}>Grupo</Link>
                            <Link to={"./Historia"} onClick={() => this.setState({ isMenuOpen: false })}>Historia</Link>
                            <Link to={"./Sede"} onClick={() => this.setState({ isMenuOpen: false })}>Sede</Link>
                            <Link to={"./Publicaciones"} onClick={() => this.setState({ isMenuOpen: false })}>Publicaciones</Link>
                            <Link to={"./institucional"} onClick={() => this.setState({ isMenuOpen: false })}>Institucional</Link>
                        </div>
                    </li>
                    <Link to={"./director"} onClick={() => this.setState({ isMenuOpen: false })}>
                        <li>
                            <h1>
                                Director
                            </h1>
                        </li>
                    </Link>
                    <Link to={"./Noticias"} onClick={() => this.setState({ isMenuOpen: false })}>
                        <li>
                            <h1>
                                Noticias
                            </h1>
                        </li>
                    </Link>
                    <Link to={"./Calendario"} onClick={() => this.setState({ isMenuOpen: false })}>
                        <li>
                            <h1>
                                Programacion
                            </h1>
                        </li>
                    </Link>
                    <li className="dropdown">
                        <h1 className="dropbtn">
                            Escuela Itinerante
                            <i className="fa fa-caret-down" />
                        </h1>
                        <div className="dropdown-content">
                            <Link to={"./talleres"} onClick={() => this.setState({ isMenuOpen: false })}>Talleres teatrales</Link>
                            <Link to={"./memoria-y-comunidad"} onClick={() => this.setState({ isMenuOpen: false })}>Memoria</Link>
                            <Link to={"./procesos-creacion"} onClick={() => this.setState({ isMenuOpen: false })}>Procesos Creación</Link>
                        </div>
                    </li>
                </ul>
                </div>
                )}

                <div className="menu-desktop-only">
                    <ul className={"nav-bar desktop-only"}>
                        <Link to={"./creaciones"}>
                            <li>
                                <h1>Creaciones</h1>
                            </li>
                        </Link>
                        <li className="dropdown">
                            <h1 className="dropbtn">
                                Nosotros
                                <i className="fa fa-caret-down" />
                            </h1>
                            <div className="dropdown-content">
                                <Link to={"./Grupo"}>Grupo</Link>
                                <Link to={"./Historia"}>Historia</Link>
                                <Link to={"./Sede"}>Sede</Link>
                                <Link to={"./Publicaciones"}>Publicaciones</Link>
                                <Link to={"./institucional"}>Institucional</Link>
                            </div>
                        </li>
                        <Link to={"./director"}>
                            <li>
                                <h1>Director</h1>
                            </li>
                        </Link>
                        <Link to={"./Noticias"}>
                            <li>
                                <h1>Noticias</h1>
                            </li>
                        </Link>
                        <Link to={"./Calendario"}>
                            <li>
                                <h1>Programacion</h1>
                            </li>
                        </Link>

                        <li className="dropdown">
                            <h1 className="dropbtn">
                                Escuela Itinerante
                                <i className="fa fa-caret-down" />
                            </h1>
                            <div className="dropdown-content">
                                <Link to={"./talleres"}>Talleres teatrales</Link>
                                <Link to={"./memoria-y-comunidad"}>Memoria y Comunidad</Link>
                                <Link to={"./procesos-creacion"}>Procesos de Creación</Link>

                            </div>

                        </li>
                    </ul>
                </div>


            </React.Fragment>
        );
    }
}

export default NavBar;