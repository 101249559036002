import React, { useState, useEffect } from 'react';
import '../../css/routes/newview-page.css';
// import Carousel from '../../components/carousel';
import {useLoaderData} from "react-router-dom";
import FirebaseService from "../../services/firebase-service";

export async function loader({params}){
    const id = params.newId;
    const data = await FirebaseService.getDocument(id, "noticias");
    const raw_name = "Noticias/"+data.title.replace(/[\:\"\?\/]/gi, '')
    const poster = await FirebaseService.getPoster(raw_name);
    const mediaList = await FirebaseService.getMediaElements(raw_name);
    return {id, data, poster, mediaList};
}

const VistaNoticia = ({ match }) => {
    const {id, data, poster, mediaList} = useLoaderData();
    const [imgs, setImgs] = useState([])
    const [videos, setVideos] = useState([])

    useEffect(() => {

        const types = new Map([["png", "img"], ["jpg", "img"], ["jpeg", "img"], ["gif", "img"], ["mp4", "video"], ["3gp", "video"]])

        var mediaVideo = mediaList.filter((filename)=> {
            const url = new URL(filename)
            const extension_slice = url.pathname.split(".")
            const extension = extension_slice[extension_slice.length-1];
            const type = types.get(extension.toLowerCase());

            return type === "video";
        });
        var mediaImages = mediaList.filter((filename)=> {
            const url = new URL(filename)
            const extension_slice = url.pathname.split(".")
            const extension = extension_slice[extension_slice.length-1];
            const type = types.get(extension.toLowerCase());
            const name = extension_slice[extension_slice.length-2]
            return type === "img" && name.slice(name.length-7, name.length) !== "Portada";
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps

        var tempArr = mediaVideo.map(
            (element)=>{
                return (
                    <video src={element} controls>
                    </video>
                )
            }
        );
        setVideos(tempArr);
        var tempArr = mediaImages.map(
            (element)=>{
                return(
                    <img src={element}/>
                );
            }
        )
        setImgs(tempArr);
    }, []); // Empty dependency array to run only once
    const date = new Date(data.year.seconds*1000);

    return (
        <div className="news-view">
            <h1>Noticia</h1>
            <div className="news-header">
                <figure>
                    <img
                        src={poster}
                        style={{objectFit: "contain"}}
                        alt=""
                    />
                </figure>
                <div className={"information"}>
                    <h2>
                        {data.title}
                    </h2>
                    <h4>
                        {date.toLocaleDateString("es")}
                    </h4>
                </div>
            </div>
            <div className="news-content">
                <p className={"text"}>
                    {data.parrafo1}
                </p>
                <div className={"media-content"}>
                    {
                        videos.length === 0?imgs.slice(0, imgs.length/2):videos
                    }
                </div>
                <p className={"text"}>
                    {data.parrafo2}
                </p>
                <div className={"media-content"}>
                    {
                        videos.length === 0?imgs.slice(imgs.length/2, imgs.length):imgs
                    }
                </div>
            </div>
        </div>
    );
};

export default VistaNoticia;
