import React, { useState, useEffect } from 'react';

import portada1 from '../media/publicaciones/1. Portada Arte de Labranza.jpg';
import portada2 from '../media/publicaciones/2. Portada Cinco textos dramáticos.jpg';

import pub1 from '../media/publicaciones/ARTE-DE-LABRANZA-Teatro-Tierra-2013-libro-312pag.pdf';
import pub2 from '../media/publicaciones/Cinco textos dramáticos.pdf';

import '../css/routes/creaciones.css';
import '../css/components/play-card.css';
import FirebaseService from "../services/firebase-service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

const PublicacionesPage = () => {


    return (
        <div className="creaciones-page">
            <h1>
                Nuestras publicaciones
            </h1>
            <div className="card-container" style={{justifyContent:"center"}}>
                <div className={"alt-effect play-card-item "}>
                    <a href={pub1} target={"_blank"} className={"more-button"}>
                        <div className={"play-content"} style={{textAlign: "center"}}>
                            <h3 style={{justifyContent: "space-evenly"}}>
                                <div>
                                    Arte de
                                    <br/>
                                    Labranza
                                </div>
                                <FontAwesomeIcon icon={faFilePdf} color={"#291100"} size={"x"}/>
                            </h3>
                        </div>
                        <div className={"play-photo"}>
                            <img src={portada1} alt={""}/>
                        </div>
                    </a>
                </div>
                <div className={"alt-effect play-card-item "}>
                    <a href={pub2} target={"_blank"} className={"more-button"}>
                        <div className={"play-content"} style={{textAlign: "center"}}>
                            <h3 style={{justifyContent: "space-evenly"}}>
                                <div>
                                    Cinco Textos Dramáticos
                                </div>
                                <FontAwesomeIcon icon={faFilePdf} color={"#291100"} size={"x"}/>
                            </h3>
                        </div>
                        <div className={"play-photo"}>
                            <img src={portada2} alt={""}/>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PublicacionesPage;
