import React, { useState, useEffect } from 'react';
import '../css/routes/creaciones.css';
import {ReactComponent as Logo} from "../media/svg/teatrotierra.svg"
import "../css/routes/land-page.css"
import Carousel from "../components/carousel";
import { Link, useLoaderData } from 'react-router-dom';
import FirebaseService from "../services/firebase-service";
import PlayCard from '../components/play-card';

export async function loader(){
    const plays = await FirebaseService.listCollection("noticias", 4);
    return { plays };
}

const LandPage = () => {
    var banners = [
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/01.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/02.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/03.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/04.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/05.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/06.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/07.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/08.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/09.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/10.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/11.jpg"},
        {image: "https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Banner/12.jpg"},

    ]
    // No need for names array as JSX can directly map over objects
    const { plays } = useLoaderData();
    return (
        <div className="land-page">
            <div className={"front-image"}>
                <Carousel images = {banners}/>

                <div className={"title"}>
                    <Logo/>
                    35 AÑOS DE EXPERIENCIA
                </div>


            </div>
            <div style={{
                height: "10px"
            }}>

            </div>

            <div className={"news-intro"}>
                <div className='creaciones-page'>
                    <h1>Noticias</h1>
                </div>
                <div className="card-container">
                    
                    {Object.values(plays.plays).map((play) => {
                        const date = new Date(play.year.seconds*1000);
                        const raw_name = "Noticias/"+play.title.replace(/[\:\"\?\/]/gi, '')
                        return (
                            <PlayCard
                                key={play.title}
                                title={play.title}
                                title_raw={raw_name}
                                description={" "}
                                id={play.id}
                            />
                        )
                    })}      
                </div>
                <div className="button-more-news">
                    <Link to={"./Noticias"}> 
                        ver otras noticias 
                    </Link>
                </div>
            </div>

            <div className={"words-row"}>
                <div className={"rectangle"}
                     style={{backgroundImage: "url('https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Hecuba.png')"}}>
                    <h1>
                        En la poética del Teatro Tierra los objetos tienen vida propia
                    </h1>
                </div>
                <div className={"square"}>
                    <h2>
                        Santiago García (Q.E.P.D.)
                    </h2>
                    <h5>
                        Director Teatro La Candelaria.
                    </h5>
                    <div className={"separador"}></div>
                    <p>
                        "El Teatro Tierra, dirigido por Juan Carlos Moyano, ha desarrollado una talentosa labor tanto en
                        lo referente a la dramaturgia de obras originales de indiscutible calidad, como en el desarrollo
                        de técnicas de actuación y de montaje que han hecho posible que la presencia de nuestro teatro
                        colombiano se coloque en un destacado lugar de la creación escénica latinoamericana"
                    </p>
                </div>
                <div className={"square"} style={{
                    backgroundImage: "url('https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/SantiagoGarcia01.png')"
                }}/>

            </div>
            <div className={"words-row"}>
                <div className={"square"} style={{
                    backgroundImage: "url('https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Gilberto-Martinez.png')"
                }}/>
                <div className={"square"}>
                    <h2>
                        Gilberto Martínez (q.e.p.d)
                    </h2>
                    <h5>
                        Dramaturgo y director <br/> Casa del Teatro de Medellín
                    </h5>
                    <div className={"separador"}></div>
                    <p>
                        "…Me acojo a los que han dicho del Teatro Tierra que están construyendo un lenguaje que es único
                        en la Dramaturgia colombiana"
                    </p>
                </div>

                <div className={"rectangle"}
                     style={{backgroundImage: "url('https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Lisistrata.png')"}}>
                    <h1>
                        El Teatro Tierra ha sido un grupo viajero. Las giras y los intercambios nos han aportado temas y
                        han generado una escuela natural.
                    </h1>
                </div>

            </div>
            <div className={"words-row"}>
                <div className={"rectangle"}
                     style={{backgroundImage: "url('https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/Voragine.png')"}}>
                    <h1>
                        Tratamos asuntos humanos, historias que conmueven, extraídas de la literatura, de la memoria
                        histórica o de las vivencias directas.
                    </h1>
                </div>
                <div className={"square"}>
                    <h2>
                        CARLOS JOSÉ REYES (Q.E.P.D.)
                    </h2>
                    <h5>
                        Dramaturgo e historiador
                    </h5>
                    <div className={"separador"}></div>
                    <p>
                        "Juan Carlos Moyano ha abierto con su teatro Tierra una fructífera vía de creación al efectuar
                        singulares lecturas, más que simples adaptaciones de novelas de estilos muy diferentes, para
                        concebir propuestas teatrales ricas en referentes y metáforas."
                    </p>
                </div>
                <div className={"square"}
                     style={{backgroundImage: "url('https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/samples/carlosjosereyes1.jpg')"}}>
                </div>
            </div>
        </div>
    );
};

export default LandPage;
